import { useAuth } from '../context/AuthContext.js';
import { Navigate } from 'react-router-dom';
import Loading from './Loading.js';


const AdminRestrictedRoute = ({ element }) => {
    const { isAdmin } = useAuth();

    if (isAdmin === null) {
        return <Loading />;
    }

    if (isAdmin) {
        return element;
    } else {
        return <Navigate to="/" />;
    }
}

export default AdminRestrictedRoute;
