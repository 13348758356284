import { CustomStatsInfos } from "../config";
import { useColorModeValue } from "@chakra-ui/react";

export const LeagueLogos = {
    orque: '/league_icons_nobg/orca.png',
    baleine: '/league_icons_nobg/whale.png',
    requin: '/league_icons_nobg/shark.png',
    poisson: '/league_icons_nobg/fish.png',
    crevette: '/league_icons_nobg/shrimp.png',
    narval: '/league_icons_nobg/narwhal.png',
    dauphin: '/league_icons_nobg/dolphin.png',
    tortue: '/league_icons_nobg/turtle.png',
    hippocampe: '/league_icons_nobg/seahorse.png',
    plancton: '/league_icons_nobg/plankton.png'
  };
  export const useLeagueColors = () => ({
    orque: useColorModeValue('#3399ff', '#1a73e8'),  // Light mode: Intense blue, Dark mode: Darker blue
    baleine: useColorModeValue('#66b2ff', '#3b82f6'),  // Light mode: Light blue, Dark mode: Slightly darker
    requin: useColorModeValue('#80ccff', '#4ea9ff'),  // Light mode: Sky blue, Dark mode: Deeper sky blue
    narval: useColorModeValue('#99e6ff', '#66bfff'),  // Light mode: Very light blue, Dark mode: Slightly darker
    dauphin: useColorModeValue('#b3ffff', '#80e9ff'),  // Light mode: Cyan, Dark mode: Deeper cyan
    tortue: useColorModeValue('#ccffe6', '#99f6df'),  // Light mode: Turquoise cyan, Dark mode: Deeper turquoise
    poisson: useColorModeValue('#b3ffcc', '#80f2b2'),  // Light mode: Pale mint green, Dark mode: Deeper mint green
    hippocampe: useColorModeValue('#99ffb3', '#66e59b'),  // Light mode: Pale green, Dark mode: Deeper pale green
    crevette: useColorModeValue('#80ff99', '#4fd587'),  // Light mode: Light green, Dark mode: Deeper light green
    plancton: useColorModeValue('#66ff80', '#39c76d')  // Light mode: Very light green, Dark mode: Deeper very light green
  });


  export const useColors = () => ({
    bgColor: useColorModeValue('white', 'gray.800'),
    blackTextColor: useColorModeValue('gray.600', 'gray.200'),
    borderColor: useColorModeValue('gray.200', 'gray.700'),
    linkColor: useColorModeValue('gray.600', 'gray.200'),
    linkHoverColor: useColorModeValue('gray.800', 'white'),
    linkActiveColor: useColorModeValue('cyan.500', 'pink.500'),
    blueButton: useColorModeValue('cyan.500', 'cyan.800'),
    blueButtonHover: useColorModeValue('cyan.600', 'cyan.900'),
    pinkButton: useColorModeValue('pink.500', 'pink.800'),
    pinkButtonHover: useColorModeValue('pink.600', 'pink.900'),
    cyanTextColor: useColorModeValue('cyan.700', 'cyan.200'),
    pinkTextColor: useColorModeValue('pink.500', 'pink.200'),
    tabBg: useColorModeValue('cyan.100', 'cyan.900'),
    tabSelectedBg: useColorModeValue('cyan.500', 'cyan.300'),
    tabColor: useColorModeValue('gray.700', 'white'),
    tabSelectedColor: useColorModeValue('white', 'black'),
    panelBg : useColorModeValue('cyan.50', 'gray.700'),
    lighterPanelBg: useColorModeValue('cyan.100', 'gray.600'),
    darkTextColor: useColorModeValue('darkslategrey', 'gray.50'),
  });

export const LeagueNames = ['plancton', 'crevette', 'hippocampe', 'poisson', 'tortue', 'dauphin', 'narval', 'requin', 'baleine', 'orque'];

export const banners = ['banner.png', 'banner2.png', 'banner3.png', 'banner4.png'];

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);


export const AgentStatus = Object.freeze({
    'ACTIVE': 'active',
    'INACTIVE': 'inactive',
    'PENDING': 'pending',
    'DELETED': 'deleted',
    'INVALID': 'invalid'
  });

export const FightStatus = Object.freeze({
    'SCHEDULED': 'scheduled',
    'IN_PROGRESS': 'in_progress',
    'DONE': 'done',
    'CANCELLED': 'cancelled'
  });


export const PalmaresInfo = [
  {
    icon: 'cliff.png',
    titleBest: 'Le meilleur Elo',
    titleWorst: 'Le pire Elo',
    dbKeyBest: 'elo',
    dbKeyWorst: 'elo',
    orderBest: 'desc',
    orderWorst: 'asc',
  },
  {
    icon: 'barbell.png',
    titleBest: 'Le plus de victoires',
    titleWorst: 'Le plus de défaites',
    dbKeyBest: 'n_victories',
    dbKeyWorst: 'n_defeats',
    orderBest: 'desc',
    orderWorst: 'desc',
  },
  {
    icon: 'run.png',
    titleBest: 'La plus longue série de victoires',
    titleWorst: 'La plus longue série de défaites',
    dbKeyBest: 'largest_win_streak',
    dbKeyWorst: 'largest_loosing_streak',
    orderBest: 'desc',
    orderWorst: 'desc',
  },
  {
    icon: 'aim.png', //'🧗'
    titleBest: 'Le plus de points marqués',
    titleWorst: 'Le moins de points marqués',
    dbKeyBest: 'n_points_scored',
    dbKeyWorst: 'n_points_scored',
    orderBest: 'desc',
    orderWorst: 'asc',
  },
  {
    icon: 'shield.png',
    titleBest: 'Le moins de points encaissés',
    titleWorst: 'Le plus de points encaissés',
    dbKeyBest: 'n_points_conceded',
    dbKeyWorst: 'n_points_conceded',
    orderBest: 'asc',
    orderWorst: 'desc',
  },
  {
    icon  : 'swim.png', //'🧗
    titleBest: 'Le plus de jours passés dans la ligue ' + capitalize(LeagueNames[LeagueNames.length - 1]),
    titleWorst: 'Le plus de jours passés dans la ligue ' + capitalize(LeagueNames[0]),
    dbKeyBest: 'n_days_in_best_league',
    dbKeyWorst: 'n_days_in_worst_league',
    orderBest: 'desc',
    orderWorst: 'desc',
  }
];

CustomStatsInfos.forEach((info) => {
  if (info.in_palmares) {
    PalmaresInfo.push({
      icon: info.palmares_image,
      titleBest: 'Le plus de ' + info.title,
      titleWorst: 'Le moins de ' + info.title,
      dbKeyBest: info.key,
      dbKeyWorst: info.key,
      valueLabel: info.description,
      orderBest: info.maximizable ? 'desc' : 'asc',
      orderWorst: info.maximizable ? 'asc' : 'desc',
      custom_stats: true
    });
  }
});

export const StatsInfos = [
  {
    'key': 'elo',
    'title': 'Peak ELO',
    'value_key': 'peak_elo',
  },
  {
    'key': 'win_streak',
    'title': 'Série de victoire',
    'value_key': 'largest_win_streak',
  },
  {
    'key': 'n_victories',
    'title': 'Victoires',
    'value_key': 'n_victories',
  },
  {
    'key': 'n_defeats',
    'title': 'Défaites',
    'value_key': 'n_defeats',
  },
];

CustomStatsInfos.forEach((info) => {
  if (info.in_palmares) {
    StatsInfos.push({
      'key': info.key,
      'title': info.title,
      'value_key': info.key,
      'custom_stat': true
    });
  }
});
