/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  LoginStudent,
  Student,
  UpdateStudent
} from '.././model'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



export const postStudentCollection = (
    student: Student, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Student>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/students`,
      student,options
    );
  }



export const getPostStudentCollectionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentCollection>>, {data: Student}> = (props) => {
          const {data} = props ?? {};

          return  postStudentCollection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentCollection>>>
    export type PostStudentCollectionMutationBody = Student
    export type PostStudentCollectionMutationError = AxiosError<unknown>

    export const usePostStudentCollection = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentCollection>>,
        TError,
        {data: Student},
        TContext
      > => {

      const mutationOptions = getPostStudentCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getStudentCollection = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Student[]>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/students`,options
    );
  }


export const getGetStudentCollectionQueryKey = () => {
    return [`https://demo.corail-lab.ca/api/students`] as const;
    }

    
export const getGetStudentCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getStudentCollection>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentCollectionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentCollection>>> = ({ signal }) => getStudentCollection({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentCollection>>>
export type GetStudentCollectionQueryError = AxiosError<unknown>

export const useGetStudentCollection = <TData = Awaited<ReturnType<typeof getStudentCollection>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentCollectionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getStudentAdminCheck = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/students/admin_check`,options
    );
  }


export const getGetStudentAdminCheckQueryKey = () => {
    return [`https://demo.corail-lab.ca/api/students/admin_check`] as const;
    }

    
export const getGetStudentAdminCheckQueryOptions = <TData = Awaited<ReturnType<typeof getStudentAdminCheck>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentAdminCheckQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentAdminCheck>>> = ({ signal }) => getStudentAdminCheck({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentAdminCheckQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentAdminCheck>>>
export type GetStudentAdminCheckQueryError = AxiosError<unknown>

export const useGetStudentAdminCheck = <TData = Awaited<ReturnType<typeof getStudentAdminCheck>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentAdminCheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postStudentLoadCsv = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/students/loadCSV`,undefined,options
    );
  }



export const getPostStudentLoadCsvMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentLoadCsv>>, void> = () => {
          

          return  postStudentLoadCsv(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentLoadCsv>>>
    
    export type PostStudentLoadCsvMutationError = AxiosError<unknown>

    export const usePostStudentLoadCsv = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostStudentLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postStudentLogin = (
    loginStudent: LoginStudent, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/students/login`,
      loginStudent,options
    );
  }



export const getPostStudentLoginMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentLogin>>, {data: LoginStudent}> = (props) => {
          const {data} = props ?? {};

          return  postStudentLogin(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentLogin>>>
    export type PostStudentLoginMutationBody = LoginStudent
    export type PostStudentLoginMutationError = AxiosError<unknown>

    export const usePostStudentLogin = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentLogin>>,
        TError,
        {data: LoginStudent},
        TContext
      > => {

      const mutationOptions = getPostStudentLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getStudentProfile = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/students/profile`,options
    );
  }


export const getGetStudentProfileQueryKey = () => {
    return [`https://demo.corail-lab.ca/api/students/profile`] as const;
    }

    
export const getGetStudentProfileQueryOptions = <TData = Awaited<ReturnType<typeof getStudentProfile>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentProfile>>> = ({ signal }) => getStudentProfile({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentProfile>>>
export type GetStudentProfileQueryError = AxiosError<unknown>

export const useGetStudentProfile = <TData = Awaited<ReturnType<typeof getStudentProfile>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getStudentItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Student>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/students/${id}`,options
    );
  }


export const getGetStudentItemQueryKey = (id: number,) => {
    return [`https://demo.corail-lab.ca/api/students/${id}`] as const;
    }

    
export const getGetStudentItemQueryOptions = <TData = Awaited<ReturnType<typeof getStudentItem>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentItem>>> = ({ signal }) => getStudentItem(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentItemQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentItem>>>
export type GetStudentItemQueryError = AxiosError<unknown>

export const useGetStudentItem = <TData = Awaited<ReturnType<typeof getStudentItem>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchStudentItem = (
    id: number,
    updateStudent: UpdateStudent, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.patch(
      `https://demo.corail-lab.ca/api/students/${id}`,
      updateStudent,options
    );
  }



export const getPatchStudentItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchStudentItem>>, {id: number;data: UpdateStudent}> = (props) => {
          const {id,data} = props ?? {};

          return  patchStudentItem(id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchStudentItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchStudentItem>>>
    export type PatchStudentItemMutationBody = UpdateStudent
    export type PatchStudentItemMutationError = AxiosError<unknown>

    export const usePatchStudentItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchStudentItem>>,
        TError,
        {id: number;data: UpdateStudent},
        TContext
      > => {

      const mutationOptions = getPatchStudentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteStudentItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `https://demo.corail-lab.ca/api/students/${id}`,options
    );
  }



export const getDeleteStudentItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStudentItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteStudentItem(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteStudentItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStudentItem>>>
    
    export type DeleteStudentItemMutationError = AxiosError<unknown>

    export const useDeleteStudentItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteStudentItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteStudentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    