import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

const ErrorMessage = ({ message }) => {
  const bgColor = useColorModeValue('red.100', 'red.900');
  const textColor = useColorModeValue('red.800', 'red.200');
  const borderColor = useColorModeValue('red.400', 'red.600');

  return (
    <Box
      bg={bgColor}
      color={textColor}
      border="1px"
      borderColor={borderColor}
      p={3}
      borderRadius="md"
      mb={4}
      mt={4}
      width="fit-content"
      mx="auto"
      textAlign="center"
    >
      <Text fontSize="l">{message}</Text>
    </Box>
  );
};

export default ErrorMessage;
