/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  Fight,
  GetFightCollectionParams,
  UpdateFight
} from '.././model'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



export const postFightCollection = (
    fight: Fight, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Fight>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/fights`,
      fight,options
    );
  }



export const getPostFightCollectionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: Fight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: Fight}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFightCollection>>, {data: Fight}> = (props) => {
          const {data} = props ?? {};

          return  postFightCollection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFightCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postFightCollection>>>
    export type PostFightCollectionMutationBody = Fight
    export type PostFightCollectionMutationError = AxiosError<unknown>

    export const usePostFightCollection = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: Fight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postFightCollection>>,
        TError,
        {data: Fight},
        TContext
      > => {

      const mutationOptions = getPostFightCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getFightCollection = (
    params?: GetFightCollectionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Fight[]>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/fights`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetFightCollectionQueryKey = (params?: GetFightCollectionParams,) => {
    return [`https://demo.corail-lab.ca/api/fights`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFightCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getFightCollection>>, TError = AxiosError<unknown>>(params?: GetFightCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightCollectionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightCollection>>> = ({ signal }) => getFightCollection(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getFightCollection>>>
export type GetFightCollectionQueryError = AxiosError<unknown>

export const useGetFightCollection = <TData = Awaited<ReturnType<typeof getFightCollection>>, TError = AxiosError<unknown>>(
 params?: GetFightCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightCollectionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getFightMatchmaking = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/fights/compute_matchmaking`,options
    );
  }


export const getGetFightMatchmakingQueryKey = () => {
    return [`https://demo.corail-lab.ca/api/fights/compute_matchmaking`] as const;
    }

    
export const getGetFightMatchmakingQueryOptions = <TData = Awaited<ReturnType<typeof getFightMatchmaking>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightMatchmakingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightMatchmaking>>> = ({ signal }) => getFightMatchmaking({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightMatchmakingQueryResult = NonNullable<Awaited<ReturnType<typeof getFightMatchmaking>>>
export type GetFightMatchmakingQueryError = AxiosError<unknown>

export const useGetFightMatchmaking = <TData = Awaited<ReturnType<typeof getFightMatchmaking>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightMatchmakingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getFightGroup = (
    groupId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Fight[]>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/fights/group/${groupId}`,options
    );
  }


export const getGetFightGroupQueryKey = (groupId: number,) => {
    return [`https://demo.corail-lab.ca/api/fights/group/${groupId}`] as const;
    }

    
export const getGetFightGroupQueryOptions = <TData = Awaited<ReturnType<typeof getFightGroup>>, TError = AxiosError<unknown>>(groupId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightGroupQueryKey(groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightGroup>>> = ({ signal }) => getFightGroup(groupId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getFightGroup>>>
export type GetFightGroupQueryError = AxiosError<unknown>

export const useGetFightGroup = <TData = Awaited<ReturnType<typeof getFightGroup>>, TError = AxiosError<unknown>>(
 groupId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightGroupQueryOptions(groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getFightItemJson = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/fights/json/${id}`,options
    );
  }


export const getGetFightItemJsonQueryKey = (id: number,) => {
    return [`https://demo.corail-lab.ca/api/fights/json/${id}`] as const;
    }

    
export const getGetFightItemJsonQueryOptions = <TData = Awaited<ReturnType<typeof getFightItemJson>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightItemJsonQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightItemJson>>> = ({ signal }) => getFightItemJson(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightItemJsonQueryResult = NonNullable<Awaited<ReturnType<typeof getFightItemJson>>>
export type GetFightItemJsonQueryError = AxiosError<unknown>

export const useGetFightItemJson = <TData = Awaited<ReturnType<typeof getFightItemJson>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightItemJsonQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postFightLoadCsv = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/fights/loadCSV`,undefined,options
    );
  }



export const getPostFightLoadCsvMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFightLoadCsv>>, void> = () => {
          

          return  postFightLoadCsv(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFightLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postFightLoadCsv>>>
    
    export type PostFightLoadCsvMutationError = AxiosError<unknown>

    export const usePostFightLoadCsv = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postFightLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostFightLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getFightItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Fight>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/fights/${id}`,options
    );
  }


export const getGetFightItemQueryKey = (id: number,) => {
    return [`https://demo.corail-lab.ca/api/fights/${id}`] as const;
    }

    
export const getGetFightItemQueryOptions = <TData = Awaited<ReturnType<typeof getFightItem>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightItem>>> = ({ signal }) => getFightItem(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFightItem>>>
export type GetFightItemQueryError = AxiosError<unknown>

export const useGetFightItem = <TData = Awaited<ReturnType<typeof getFightItem>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchFightItem = (
    id: number,
    updateFight: UpdateFight, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Fight>> => {
    
    return axios.patch(
      `https://demo.corail-lab.ca/api/fights/${id}`,
      updateFight,options
    );
  }



export const getPatchFightItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFightItem>>, {id: number;data: UpdateFight}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFightItem(id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFightItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchFightItem>>>
    export type PatchFightItemMutationBody = UpdateFight
    export type PatchFightItemMutationError = AxiosError<unknown>

    export const usePatchFightItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFightItem>>,
        TError,
        {id: number;data: UpdateFight},
        TContext
      > => {

      const mutationOptions = getPatchFightItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteFightItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `https://demo.corail-lab.ca/api/fights/${id}`,options
    );
  }



export const getDeleteFightItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFightItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteFightItem(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFightItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFightItem>>>
    
    export type DeleteFightItemMutationError = AxiosError<unknown>

    export const useDeleteFightItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFightItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteFightItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    