import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import React from 'react';
import {
  ChakraProvider,
  theme,
  ColorModeScript
} from '@chakra-ui/react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Profile from './components/Profile';
import FightDetails from './components/FightDetails';
import LeaderBoard from './components/Leaderboard';
import NotFoundPage from "./components/NotFoundPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";
import Palmares from './components/Palmares';
import FightHistory from "./components/FightHistory";
import { FightStatus } from "./constants/constants";
import RulesDisplay from "./components/RulesDisplay";
import AdminDashboard from "./components/AdminDashboard";
import AdminRestrictedRoute from "./components/AdminRestrictedRoute";

function App() {
  
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <AuthProvider>
        <ModalProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
              <Route path="/fights/:fightId" element={<FightDetails />} />
              <Route path="/leaderboard" element={<LeaderBoard />} />
              <Route path="/history" element={<FightHistory status={FightStatus.DONE} />} />
              <Route path="/future" element={<FightHistory status={FightStatus.SCHEDULED}/>} />
              <Route path="/palmares" element={<Palmares />} />
              <Route path="/rules" element={<RulesDisplay />} />
              <Route path="/admin" element={<AdminRestrictedRoute element={<AdminDashboard />} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </Router>
        </ModalProvider>
      </AuthProvider>
      
    </ChakraProvider>
  );
}

export default App;
