import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
    HStack,
    IconButton,
    useToast,
    FormControl,
    FormLabel,
    Select
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { getStudentCollection, postStudentCollection, patchStudentItem, deleteStudentItem } from '../api/students/students.ts';
import { getGroupCollection } from '../api/groups/groups.ts';
import { useAuth } from '../context/AuthContext.js';
import { useColors } from '../constants/constants.js';

const StudentPanel = () => {
    const [students, setStudents] = useState([]);
    const [newStudent, setNewStudent] = useState({ username: '', id: '', group_id: '', password: '' });
    const [editStudentId, setEditStudentId] = useState(null);
    const [editStudentData, setEditStudentData] = useState({});
    const [groups, setGroups] = useState({});
    const toast = useToast();
    const { authState } = useAuth();
    const colors = useColors();

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await getGroupCollection();
                let groups = {};
                response.data.forEach(group => {
                    groups[group.id] = group.name;
                });
                setGroups(groups);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };
        fetchGroups();
    }, []);

    useEffect(() => {
        fetchStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchStudents = async () => {
        try {
            const response = await getStudentCollection({ headers: { Authorization: `Bearer ${authState.token}` } });
            setStudents(response.data);
        } catch (error) {
            toast({
                title: "Error fetching students.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCreate = async () => {
        try {
            await postStudentCollection(newStudent, { headers: { Authorization: `Bearer ${authState.token}` } });
            setNewStudent({ username: '', id: '', group_id: '', password: '' });
            fetchStudents();
            toast({
                title: "Student created.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error creating student.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            if (editStudentData.role === 'admin') { delete editStudentData.group_id; }
            if (editStudentData.password === '') { delete editStudentData.password; }
            await patchStudentItem(id, editStudentData, { headers: { Authorization: `Bearer ${authState.token}` } });
            setEditStudentId(null);
            fetchStudents();
            toast({
                title: "Student updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error updating student.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteStudentItem(id, { headers: { Authorization: `Bearer ${authState.token}` } });
            fetchStudents();
            toast({
                title: "Student deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error deleting student.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const startEditing = (student) => {
        setEditStudentId(student.id);
        delete student.password
        setEditStudentData(student);
    };

    const cancelEditing = () => {
        setEditStudentId(null);
        setEditStudentData({});
    };

    const confirmEditing = async () => {
        await handleUpdate(editStudentId);
    };

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="65%" boxShadow="lg">
            <Box p={5} bg={colors.lighterPanelBg} borderRadius="md" mx="auto" w="50%" boxShadow="md">
                <VStack spacing={4}>
                    <Box fontSize="xl" fontWeight="bold" mb={4}>
                        Créer un nouvel étudiant
                    </Box>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">Nom de l'étudiant</FormLabel>
                            <Input
                                w="70%"
                                placeholder="Nom"
                                value={newStudent.username}
                                onChange={(e) => setNewStudent({ ...newStudent, username: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            />
                        </HStack>
                    </FormControl>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">ID de l'étudiant</FormLabel>
                            <Input
                                w="70%"
                                placeholder="ID"
                                value={newStudent.id}
                                onChange={(e) => setNewStudent({ ...newStudent, id: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            />
                        </HStack>
                    </FormControl>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">Rôle</FormLabel>
                            <Select
                                w="70%" 
                                placeholder="Choisir un rôle"
                                value={newStudent.role}
                                onChange={(e) => setNewStudent({ ...newStudent, role: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            >
                                <option value="student">Étudiant</option>
                                <option value="admin">Admin</option>
                            </Select>
                        </HStack>
                    </FormControl>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">Groupe</FormLabel>
                            <Select
                                w="70%"
                                placeholder="Choisir un groupe"
                                value={newStudent.group_id}
                                onChange={(e) => setNewStudent({ ...newStudent, group_id: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            >
                                {Object.keys(groups).map((key) => (
                                    <option key={key} value={key}>{groups[key]}</option>
                                ))}
                            </Select>
                        </HStack>
                    </FormControl>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">Mot de passe</FormLabel>
                            <Input
                                w="70%"
                                placeholder="Mot de passe"
                                type="password"
                                value={newStudent.password}
                                onChange={(e) => setNewStudent({ ...newStudent, password: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            />
                        </HStack>
                    </FormControl>
                    <Button colorScheme="cyan" onClick={handleCreate}>
                        Créer
                    </Button>
                </VStack>
            </Box>

            <Table variant="striped" bgColor={colors.tabBg} mt={20} borderRadius="md" boxShadow="md">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Rôle</Th>
                        <Th>Nom</Th>
                        <Th>Groupe</Th>
                        <Th>Mot de passe</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {students.map((student) => (
                        <Tr key={student.id}>
                            <Td fontWeight="bold">{student.id}</Td>
                            <Td>{editStudentId === student.id ? (
                                <Select
                                    value={editStudentData.role}
                                    onChange={(e) =>
                                        setEditStudentData({ ...editStudentData, role: e.target.value })}
                                    borderColor="cyan.600"
                                    focusBorderColor="cyan.400"
                                >
                                    <option value="student">Étudiant</option>
                                    <option value="admin">Admin</option>
                                </Select>
                            ) : (
                                student.role === 'student' ? 'Étudiant' : 'Admin'
                            )}</Td>

                            <Td>
                                {editStudentId === student.id ? (
                                    <Input
                                        value={editStudentData.username}
                                        onChange={(e) =>
                                            setEditStudentData({ ...editStudentData, username: e.target.value })
                                        }
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    student.username
                                )}
                            </Td>
                            <Td>
                                {editStudentId === student.id ? (
                                    <Select
                                        value={editStudentData.group_id}
                                        onChange={(e) =>
                                            setEditStudentData({ ...editStudentData, group_id: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(groups).map((key) => (
                                            <option key={key} value={key}>{groups[key]}</option>
                                        ))}
                                    </Select>
                                ) : (
                                    groups[student.group_id]
                                )}
                            </Td>
                            <Td>
                                {editStudentId === student.id ? (
                                    <Input
                                        placeholder="Mot de passe"
                                        type="password"
                                        //value={editStudentData.password}
                                        onChange={(e) =>
                                            setEditStudentData({ ...editStudentData, password: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    '...'
                                )}
                            </Td>
                            <Td>
                                {editStudentId === student.id ? (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<CheckIcon />}
                                            colorScheme="green"
                                            onClick={confirmEditing}
                                        />
                                        <IconButton
                                            icon={<CloseIcon />}
                                            colorScheme="red"
                                            onClick={cancelEditing}
                                        />
                                    </HStack>
                                ) : (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<EditIcon />}
                                            colorScheme="cyan"
                                            onClick={() => startEditing(student)}
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            colorScheme="pink"
                                            onClick={() => handleDelete(student.id)}
                                        />
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default StudentPanel;
