import React from 'react';
import { Box, Flex, Heading, Badge, Icon } from '@chakra-ui/react';
import AgentGrid from './AgentGrid';
import { ArrowDownIcon, ChevronDownIcon, ChevronUpIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { FaEquals } from "react-icons/fa";

import { LeagueNames } from '../constants/constants';
import { useColors } from '../constants/constants.js';

const GroupCard = ({ group, parentIndex, selectedGroup, handleGroupClick }) => {
  const colors = useColors();

  return (
    <Box
      bgColor={colors.bgColor}
      p={4}
      mb={4}
      rounded="2xl"
      border={group.n_victories >= group.n_defeats ? '2px solid green' : '2px solid red'}
      cursor={'pointer'}
    >
      <Flex justify="space-between" align="center" onClick={() => handleGroupClick(group)}>
        {/* <Box>
        
      </Box> */}
        <Box>
          <Flex align="center">
            {(group.previous_league !== '' && (LeagueNames.indexOf(group.league) > LeagueNames.indexOf(group.previous_league))) ?
              <ArrowUpIcon w={5} h={6} color={"green"} mr={3} /> :
              (group.previous_league !== '' && (LeagueNames.indexOf(group.league) < LeagueNames.indexOf(group.previous_league))) ?
                <ArrowDownIcon w={5} h={6} color={"red"} mr={3} />
                :
                <Icon as={FaEquals} w={5} h={5} color={"gray"} mr={3} />
            }
            <Heading as="h3" size="md" color={colors.darkTextColor}>
              {group.name} ({group.elo} Elo)
            </Heading>
          </Flex>
        </Box>
        <Flex align="center">
          <Badge colorScheme={group.n_victories > group.n_defeats ? 'green' : 'red'} fontSize="lg" ml={4}>
            {group.n_victories} Victoires / {group.n_defeats} Défaites
          </Badge>
          {(!selectedGroup || selectedGroup !== group) ? (
            <ChevronDownIcon w={5} h={5} />
          ) : (
            <ChevronUpIcon w={5} h={5} />
          )}
        </Flex>
      </Flex>
      {selectedGroup === group && <AgentGrid agents={group.agents} />}
    </Box>
  );
};

export default GroupCard;
