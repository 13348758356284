import React, { useState, useEffect } from 'react';
import {
    Box,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    HStack,
    IconButton,
    useToast,
    Select
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { getAgentCollection, patchAgentItem, deleteAgentItem } from '../api/agents/agents.ts';
import { getGroupCollection } from '../api/groups/groups.ts';
import { AgentStatus, useColors} from '../constants/constants.js';
import { useAuth } from '../context/AuthContext.js';

const AgentPanel = () => {
    const [agents, setAgents] = useState([]);
    const [editAgentId, setEditAgentId] = useState(null);
    const [editAgentData, setEditAgentData] = useState({});
    const [groups, setGroups] = useState({});
    const toast = useToast();
    const { authState } = useAuth();
    const colors = useColors();

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await getGroupCollection();
                let groups = {};
                response.data.forEach(group => {
                    groups[group.id] = group.name;
                });
                setGroups(groups);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };
        fetchGroups();
    }, []);

    useEffect(() => {
        fetchAgents();
        // eslint-disable-next-line
    }, []);

    const fetchAgents = async () => {
        try {
            const response = await getAgentCollection();
            setAgents(response.data);
        } catch (error) {
            toast({
                title: "Error fetching agents.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleUpdate = async (id) => {
        try {
            await patchAgentItem(id, editAgentData, { headers: { Authorization: `Bearer ${authState.token}` } });
            setEditAgentId(null);
            fetchAgents();
            toast({
                title: "Agent updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error updating agent.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteAgentItem(id, { headers: { Authorization: `Bearer ${authState.token}` } });
            fetchAgents();
            toast({
                title: "Agent deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error deleting agent.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const startEditing = (agent) => {
        setEditAgentId(agent.id);
        setEditAgentData({ name: agent.name, group_id: agent.group.id, status : agent.status, refusal_message : agent.refusal_message});
    };

    const cancelEditing = () => {
        setEditAgentId(null);
        setEditAgentData({});
    };

    const confirmEditing = async () => {
        await handleUpdate(editAgentId);
    };

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="65%" boxShadow="lg">
            <Table variant="striped" bgColor={colors.tabBg}  borderRadius="md" boxShadow="md">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Nom</Th>
                        <Th>Groupe</Th>
                        <Th>Statut</Th>
                        <Th>Message de refus</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {agents.map((agent) => (
                        <Tr key={agent.id}>
                            <Td fontWeight="bold">{agent.id}</Td>
                            <Td>
                                {editAgentId === agent.id ? (
                                    <Input
                                        value={editAgentData.name}
                                        onChange={(e) =>
                                            setEditAgentData({ ...editAgentData, name: e.target.value })
                                        }
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    agent.name
                                )}
                            </Td>
                            <Td>
                                {editAgentId === agent.id ? (
                                    <Select
                                        value={editAgentData.group_id}
                                        onChange={(e) =>
                                            setEditAgentData({ ...editAgentData, group_id: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(groups).map((key) => (
                                            <option key={key} value={key}>{groups[key]}</option>
                                        ))}
                                    </Select>
                                ) : (
                                    groups[agent.group.id]
                                )}
                            </Td>
                            <Td>
                                {editAgentId === agent.id ? (
                                    <Select
                                        value={editAgentData.status}
                                        onChange={(e) =>
                                            setEditAgentData({ ...editAgentData, status: e.target.value })}
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {Object.keys(AgentStatus).map((key) => (
                                            <option key={key} value={AgentStatus[key]}>{key}</option>
                                        ))}

                                    </Select>
                                ) : (
                                    agent.status.toUpperCase()
                                )
                                }
                            </Td>
                            <Td>
                                {editAgentId === agent.id ? (
                                    <Input
                                        value={editAgentData.refusal_message}
                                        onChange={(e) =>
                                            setEditAgentData({ ...editAgentData, refusal_message: e.target.value })
                                        }
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                        />
                                    ) : (
                                        agent.refusal_message ?? ''
                                    )}

                            </Td>                            
                            <Td>
                                {editAgentId === agent.id ? (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<CheckIcon />}
                                            colorScheme="green"
                                            onClick={confirmEditing}
                                        />
                                        <IconButton
                                            icon={<CloseIcon />}
                                            colorScheme="red"
                                            onClick={cancelEditing}
                                        />
                                    </HStack>
                                ) : (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<EditIcon />}
                                            colorScheme="cyan"
                                            onClick={() => startEditing(agent)}
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            colorScheme="pink"
                                            onClick={() => handleDelete(agent.id)}
                                        />
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default AgentPanel;
