import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';
import ErrorPopup from './ErrorPopup';
import AgentItem from './AgentItem';
import { putAgentDelete } from '../api/agents/agents.ts';
import AlertVerification from './AlertVerification';
import { useAuth } from '../context/AuthContext';
import { useColors } from '../constants/constants.js';

const AgentList = ({ initialAgents }) => {
  const [agents, setAgents] = useState(initialAgents);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [agent, setAgent] = useState({});
  const { authState } = useAuth();
  const colors = useColors();

  useEffect(() => {
    setAgents(initialAgents);
  }, [initialAgents]);

  const removeAgent = (id) => {
    setAgents((prevAgents) => prevAgents.filter((agent) => agent.id !== id));
  };

  const handleDeleteAgent = async () => {
    try {
      await putAgentDelete(agent.id, { headers: { Authorization: `Bearer ${authState.token}` } });
      removeAgent(agent.id);
      setIsDialogOpen(false);
    } catch (error) {
      setErrorMessage('Erreur lors de la suppression de l\'agent');
      setShowError(true);
    }
  };

  return (
    <>
      <ErrorPopup message={errorMessage} onClose={() => setShowError(false)} isOpen={showError} />
      <TableContainer marginY={4}>
        <AlertVerification isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} handleDelete={handleDeleteAgent} name={agent.name} />
        <Table variant='striped' mt={5} bgColor={colors.tabBg} size={'lg'} borderRadius={'lg'} boxShadow={'md'}>
          <Thead>
            <Tr>
              <Th textAlign="center" fontSize={'g'}></Th>
              <Th textAlign="center" fontSize={'g'}>Nom de l'agent</Th>
              <Th textAlign="center" fontSize={'g'}>Performance</Th>
              <Th textAlign="center" fontSize={'g'}>ELO</Th>
              <Th textAlign="center" fontSize={'g'}>Actif</Th>
            </Tr>
          </Thead>
          <Tbody>
            {agents.map((agent) => (
              <AgentItem key={agent.id} agent={agent} setErrorMessage={setErrorMessage} setShowError={setShowError} setIsDialogOpen={setIsDialogOpen} setAgent={setAgent} />
            ))}
          </Tbody>
        </Table>
      </TableContainer></>
  );
};

export default AgentList;
