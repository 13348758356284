import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    //useDisclosure
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import { useModal } from '../context/ModalContext';

export default function LoginModal() {
    const { isLoginModalOpen, closeLoginModal, openLoginModal } = useModal();
    const { login } = useAuth();
    const [studentId, setStudentId] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        await login(studentId, password);
        closeLoginModal();
    };

    return (
        <>
            <Button
                as={'a'}
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                fontWeight={600}
                color={'white'}
                bg={'pink.400'}
                href={'#'}
                _hover={{ bg: 'pink.300' }}
                onClick={openLoginModal}
            >
                Connexion
            </Button>
            <Modal isOpen={isLoginModalOpen} onClose={closeLoginModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Connexion</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Matricule PolyMTL</FormLabel>
                            <Input
                                type="text"
                                placeholder="Matricule (xxxxxxx)"
                                value={studentId}
                                onChange={(e) => setStudentId(e.target.value.replace(/\D/, ''))}
                                pattern="[0-9]*"
                                onInput={(e) => e.target.validity.valid || (e.target.value = studentId)}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button bg={'pink.500'} color="white" mr={3} onClick={closeLoginModal}>
                            Fermer
                        </Button>
                        <Button bg={'cyan.500'} color="white" onClick={handleLogin}>
                            Entrer
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
