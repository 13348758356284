import React, { useState, useEffect } from 'react';
import {
  Container, Heading, Accordion, useColorModeValue
} from '@chakra-ui/react';
import Loading from './Loading';
import ErrorMessage from './ErrorMessage';
import LeagueAccordionItem from './LeagueAccordionItem';
import { LeagueNames } from '../constants/constants.js';
import { getGroupCollection } from '../api/groups/groups.ts';

const Leaderboard = () => {
  const [groups, setGroups] = useState(null);
  const textColor = useColorModeValue('black', 'gray.300');

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupStats = await getGroupCollection({ include_stats: true });
        setGroups(groupStats.data);
      } catch (error) {
        setGroups([]);
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  let color = useColorModeValue('gray.50', 'gray.900');

  if (groups === null) return <Loading />;
  if (groups.length === 0) return <ErrorMessage message="Erreur lors du chargement des données" />;

  const leagues = {};
  LeagueNames.slice().reverse().forEach((name) => {
    leagues[name] = groups.filter(group => group.league.includes(name.slice(1))).sort((a, b) => b.elo - a.elo);
  });

  return (
    <Container maxW={'4xl'} bg={color} padding={5}>
      <Heading as="h1" size="xl" color="teal.500" mb={6} textAlign={'center'}>
        Classement des ligues
      </Heading>
      <Accordion allowMultiple>
        {Object.keys(leagues).map((league, index) => (
          <LeagueAccordionItem
            key={league}
            league={league}
            index={index}
            leagueGroups={leagues[league]}
            textColor={textColor}
          />
        ))}
      </Accordion>
    </Container>
  );
};

export default Leaderboard;
