import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
    HStack,
    IconButton,
    useToast,
    FormControl,
    FormLabel,
    Select,
} from '@chakra-ui/react';
import { capitalize, LeagueNames, useColors } from '../constants/constants.js';
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { getGroupCollection, postGroupCollection, patchGroupItem, deleteGroupItem } from '../api/groups/groups.ts';
import { useAuth } from '../context/AuthContext.js';

const GroupPanel = () => {
    const [groups, setGroups] = useState([]);
    const [newGroup, setNewGroup] = useState({ name: '' });
    const [editGroupId, setEditGroupId] = useState(null);
    const [editGroupData, setEditGroupData] = useState({});
    const toast = useToast();
    const { authState } = useAuth();
    const colors = useColors();

    useEffect(() => {
        fetchGroups();
        // eslint-disable-next-line
    }, []);

    const fetchGroups = async () => {
        try {
            const response = await getGroupCollection();
            setGroups(response.data);
        } catch (error) {
            toast({
                title: "Error fetching groups.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCreate = async () => {
        try {
            await postGroupCollection(newGroup, { headers: { Authorization: `Bearer ${authState.token}` } });
            setNewGroup({ name: '' });
            fetchGroups();
            toast({
                title: "Group created.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error creating group.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            await patchGroupItem(id, editGroupData, { headers: { Authorization: `Bearer ${authState.token}` } });
            setEditGroupId(null);
            fetchGroups();
            toast({
                title: "Group updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }
        catch (error) {
            toast({
                title: "Error updating group.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteGroupItem(id, { headers: { Authorization: `Bearer ${authState.token}` } });
            fetchGroups();
            toast({
                title: "Group deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error deleting group.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const startEditing = (group) => {
        setEditGroupId(group.id);
        setEditGroupData({ name: group.name, league: group.league_history.length ? capitalize(group.league_history[group.league_history.length - 1]) : '' });
    };

    const cancelEditing = () => {
        setEditGroupId(null);
        setEditGroupData({});
    };

    const confirmEditing = async () => {
        await handleUpdate(editGroupId);
    };

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="65%" boxShadow="lg">
            <Box p={5} bg={colors.lighterPanelBg} borderRadius="md" mx="auto" w="50%" boxShadow="md">
                <VStack spacing={4}>
                    <Box fontSize="xl" fontWeight="bold" mb={4}>
                        Créer un nouveau groupe
                    </Box>
                    <FormControl as='form' autoComplete="off">
                        <HStack justify="space-between">
                            <FormLabel w="30%" textAlign="left" fontWeight="bold">Nom du groupe</FormLabel>
                            <Input
                                w="70%"
                                placeholder="Nom"
                                value={newGroup.name}
                                onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                                borderColor="cyan.600"
                                focusBorderColor="cyan.400"
                            />
                        </HStack>
                    </FormControl>
                    <Button colorScheme="cyan" onClick={handleCreate}>
                        Créer
                    </Button>
                </VStack>
            </Box>

            <Table variant="striped" bgColor={colors.tabBg} mt={20} borderRadius="md" boxShadow="md">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Nom</Th>
                        <Th>Ligue</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {groups.map((group) => (
                        <Tr key={group.id}>
                            <Td fontWeight="bold">{group.id}</Td>
                            <Td>
                                {editGroupId === group.id ? (
                                    <Input
                                        value={editGroupData.name}
                                        onChange={(e) =>
                                            setEditGroupData({ ...editGroupData, name: e.target.value })
                                        }
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    />
                                ) : (
                                    group.name
                                )}
                            </Td>
                            <Td>
                                {editGroupId === group.id ? (
                                    <Select
                                        value={editGroupData.league.toLowerCase()}
                                        onChange={(e) =>
                                            setEditGroupData({ ...editGroupData, league: e.target.value })
                                        }
                                        borderColor="cyan.600"
                                        focusBorderColor="cyan.400"
                                    >
                                        {LeagueNames.map((league) => (
                                            <option key={league} value={league}>
                                                {capitalize(league)}
                                            </option>
                                        ))}
                                    </Select>
                                ) : (
                                    group.league_history.length ? capitalize(group.league_history[group.league_history.length - 1]) : ''
                                )}
                            </Td>
                            <Td>
                                {editGroupId === group.id ? (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<CheckIcon />}
                                            colorScheme="green"
                                            onClick={confirmEditing}
                                        />
                                        <IconButton
                                            icon={<CloseIcon />}
                                            colorScheme="red"
                                            onClick={cancelEditing}
                                        />
                                    </HStack>
                                ) : (
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<EditIcon />}
                                            colorScheme="cyan"
                                            onClick={() => startEditing(group)}
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            colorScheme="pink"
                                            onClick={() => handleDelete(group.id)}
                                        />
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default GroupPanel;
