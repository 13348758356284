// NotFoundPage.js

import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box textAlign="center" mt="20">
      <Heading size="xl" mb="4">Tu t'es perdu ?</Heading>
      <Text fontSize="lg" mb="4">La page que vous recherchez n'existe pas.</Text>
      <Button as={RouterLink} to="/" colorScheme="pink" size="md">
        Retour à la page d'accueil
      </Button>
    </Box>
  );
};

export default NotFoundPage;
