import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Grid, GridItem, Center, Image, Tabs, TabList, TabPanels, Tab, TabPanel, Divider, Flex, useColorModeValue } from '@chakra-ui/react';
import { GiTrophyCup, GiSnail } from "react-icons/gi";
import { getGroupCollection } from '../api/groups/groups.ts';
import { PalmaresInfo } from '../constants/constants.js';
import ErrorMessage from './ErrorMessage.js';
import Loading from './Loading.js';

const rankColors = ['gold', 'silver', '#cd7f32'];

const fetchData = async (setData) => {
    try {
        const response = await getGroupCollection({ include_stats: true });
        let groups = []
        for (const group of response.data) {
            let groupData = {};
            groupData.name = group.name;
            PalmaresInfo.forEach(info => {
                groupData[info.dbKeyBest] = info.custom_stats ? group.custom_stats[info.dbKeyBest] : group[info.dbKeyBest];
                groupData[info.dbKeyWorst] = info.custom_stats ? group.custom_stats[info.dbKeyWorst] : group[info.dbKeyWorst];
            });
            groups.push(groupData);
        }
        const palmares = PalmaresInfo.map((info, index) => {
            const bestGroups = groups.sort((a, b) => (info.orderBest === 'desc' ?
                b[info.dbKeyBest] - a[info.dbKeyBest] :
                a[info.dbKeyBest] - b[info.dbKeyBest])).slice(0, 3)
            const worstGroups = groups.sort((a, b) => (info.orderWorst === 'desc' ?
                b[info.dbKeyWorst] - a[info.dbKeyWorst] :
                a[info.dbKeyWorst] - b[info.dbKeyWorst])).slice(0, 3)
            return {
                ...info,
                bestGroups,
                worstGroups
            };
        });

        setData(palmares);
    }
    catch (error) {
        setData([]);
    }
};

const renderCategory = ({ icon, title, groups, dbKey, iconComponent, type }, index, array, bgColor, textColor) => (
    <GridItem
        bg={bgColor}
        p={6}
        borderRadius="lg"
        boxShadow="md"
        key={`${title}`}
        colSpan={array.length % 2 !== 0 && index === array.length - 1 ? 2 : 1}
    >
        <Center flexDirection="column" textAlign="center">
            <Image
                src={`/${type==="best" ? 'succeed': 'fail'}/${icon}`}
                width={20}
                mb={4}
                alt={`${title} Icon`}
                objectFit="contain"
            />
            <Heading as="h2" size="md" mb={4} color={textColor}>
                {title}
            </Heading>
            <Divider mb={3} />
            <Flex justifyContent="space-around" width="100%">
                {groups.map((group, idx) => (
                    <Box key={idx} textAlign="center">
                        <Center flexDirection="column">
                            {React.createElement(iconComponent, { size: 30, color: rankColors[idx] })}
                            <Text mb={1} mt={1} as="b" color={textColor}>
                                {group[dbKey]}
                            </Text>
                            <Text fontSize="lg" p={1} color={textColor}>
                                {group.name}
                            </Text>
                        </Center>
                    </Box>
                ))}
            </Flex>
        </Center>
    </GridItem>
);

const Palmares = () => {
    const [data, setData] = useState(null);
    const [showWorstTab, setShowWorstTab] = useState(false);
    const [bestTabClickCount, setBestTabClickCount] = useState(0);

    const bgColor = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('black', 'white');
    const panelBgColor = useColorModeValue('gray.50', 'gray.800');
    const headingColor = useColorModeValue('teal.500', 'teal.300');

    useEffect(() => {
        fetchData(setData);
    }, []);

    const handleBestTabClick = () => {
        setBestTabClickCount(prev => prev + 1);
        if (bestTabClickCount + 1 === 20) {
            setShowWorstTab(true);
        }
    };

    if (data === null) {
        return <Loading />;
    }
    if (data.length === 0) {
        return <ErrorMessage message="Erreur lors de la récupération des données" />;
    }

    return (
        <Box p={5} bg={panelBgColor} minH="100vh" maxW="7xl" mx="auto" borderRadius="lg">
            <Heading as="h1" mb={10} color={headingColor} textAlign="center">
                Palmarès
            </Heading>
            <Tabs variant="soft-rounded" colorScheme="teal">
                <TabList justifyContent="center" mb={4}>
                    <Tab onClick={handleBestTabClick} cursor="inherit">
                        Les meilleurs
                    </Tab>
                    {showWorstTab && <Tab>Les pires</Tab>}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                            {data.map((info, index, array) =>
                                renderCategory(
                                    { ...info, title: info.titleBest, groups: info.bestGroups, dbKey: info.dbKeyBest, iconComponent: GiTrophyCup, type: 'best' },
                                    index,
                                    array,
                                    bgColor,
                                    textColor
                                )
                            )}
                        </Grid>
                    </TabPanel>
                    {showWorstTab && (
                        <TabPanel>
                            <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                                {data.map((info, index, array) =>
                                    renderCategory(
                                        { ...info, title: info.titleWorst, groups: info.worstGroups, dbKey: info.dbKeyWorst, iconComponent: GiSnail, type: 'worst' },
                                        index,
                                        array,
                                        bgColor,
                                        textColor
                                    )
                                )}
                            </Grid>
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default Palmares;
