/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  GetGroupCollectionParams,
  GetGroupItemParams,
  Group,
  UpdateGroup
} from '.././model'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



export const postGroupCollection = (
    group: Group, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Group>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/groups`,
      group,options
    );
  }



export const getPostGroupCollectionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: Group}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: Group}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postGroupCollection>>, {data: Group}> = (props) => {
          const {data} = props ?? {};

          return  postGroupCollection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostGroupCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postGroupCollection>>>
    export type PostGroupCollectionMutationBody = Group
    export type PostGroupCollectionMutationError = AxiosError<unknown>

    export const usePostGroupCollection = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: Group}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postGroupCollection>>,
        TError,
        {data: Group},
        TContext
      > => {

      const mutationOptions = getPostGroupCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getGroupCollection = (
    params?: GetGroupCollectionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/groups`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetGroupCollectionQueryKey = (params?: GetGroupCollectionParams,) => {
    return [`https://demo.corail-lab.ca/api/groups`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getGroupCollection>>, TError = AxiosError<unknown>>(params?: GetGroupCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupCollectionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupCollection>>> = ({ signal }) => getGroupCollection(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupCollection>>>
export type GetGroupCollectionQueryError = AxiosError<unknown>

export const useGetGroupCollection = <TData = Awaited<ReturnType<typeof getGroupCollection>>, TError = AxiosError<unknown>>(
 params?: GetGroupCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupCollectionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postGroupLoadCsv = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/groups/loadCSV`,undefined,options
    );
  }



export const getPostGroupLoadCsvMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postGroupLoadCsv>>, void> = () => {
          

          return  postGroupLoadCsv(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostGroupLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postGroupLoadCsv>>>
    
    export type PostGroupLoadCsvMutationError = AxiosError<unknown>

    export const usePostGroupLoadCsv = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postGroupLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostGroupLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getGroupItem = (
    id: number,
    params?: GetGroupItemParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/groups/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetGroupItemQueryKey = (id: number,
    params?: GetGroupItemParams,) => {
    return [`https://demo.corail-lab.ca/api/groups/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupItemQueryOptions = <TData = Awaited<ReturnType<typeof getGroupItem>>, TError = AxiosError<unknown>>(id: number,
    params?: GetGroupItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupItemQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupItem>>> = ({ signal }) => getGroupItem(id,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupItemQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupItem>>>
export type GetGroupItemQueryError = AxiosError<unknown>

export const useGetGroupItem = <TData = Awaited<ReturnType<typeof getGroupItem>>, TError = AxiosError<unknown>>(
 id: number,
    params?: GetGroupItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupItemQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchGroupItem = (
    id: number,
    updateGroup: UpdateGroup, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Group>> => {
    
    return axios.patch(
      `https://demo.corail-lab.ca/api/groups/${id}`,
      updateGroup,options
    );
  }



export const getPatchGroupItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchGroupItem>>, {id: number;data: UpdateGroup}> = (props) => {
          const {id,data} = props ?? {};

          return  patchGroupItem(id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchGroupItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchGroupItem>>>
    export type PatchGroupItemMutationBody = UpdateGroup
    export type PatchGroupItemMutationError = AxiosError<unknown>

    export const usePatchGroupItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchGroupItem>>,
        TError,
        {id: number;data: UpdateGroup},
        TContext
      > => {

      const mutationOptions = getPatchGroupItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteGroupItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `https://demo.corail-lab.ca/api/groups/${id}`,options
    );
  }



export const getDeleteGroupItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroupItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteGroupItem(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGroupItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroupItem>>>
    
    export type DeleteGroupItemMutationError = AxiosError<unknown>

    export const useDeleteGroupItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteGroupItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteGroupItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    