import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, Button, Center } from '@chakra-ui/react';
import { getFightCollection } from '../api/fights/fights.ts';
import FightCard from './FightCard.js';
import Loading from './Loading.js';
import ErrorMessage from './ErrorMessage.js';
import SearchBar from './SearchBar.js';
import { FightStatus, useColors } from '../constants/constants.js';
import { useColorModeValue } from '@chakra-ui/react';

const FightHistory = ({ status }) => {
    const [fights, setFights] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFights, setFilteredFights] = useState([]);
    const colors = useColors();
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const textColor = useColorModeValue('teal.500', 'teal.300');
    const buttonColorScheme = useColorModeValue('pink', 'purple');

    useEffect(() => {
        const fetchFights = async (page, limit) => {
            try {
                setLoading(true);
                const params = {
                        page: page,
                        limit: limit,
                        status: status
                    }
            
                const response = await getFightCollection(params);
                response.data = response.data.sort((a, b) => status === FightStatus.DONE ? (new Date(a.date) - new Date(b.date)) :
                                                     (new Date(a.scheduled_date) - new Date(b.scheduled_date)));
                if (response.data.length === 0) {
                    setHasMore(false);
                } else {
                    setFights((prevFights) => [...prevFights, ...response.data]);
                }
            } catch (error) {
                setError('Erreur lors du chargement des données');
                setHasMore(false);
            } finally {
                setLoading(false);
            }
        };
        fetchFights(page, 20);
    }, [page, status]);

    useEffect(() => {
        setFilteredFights(
            fights.filter((fight) =>
                fight.player1.group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                fight.player2.group.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, fights]);

    if (error) {
        return <ErrorMessage message={error} />;
    }

    return (
        <Box padding={4} bg={bgColor} minHeight="100vh" maxW={'5xl'} margin="auto">
          <Text fontSize="4xl" fontWeight="bold" textAlign="center" mb={6} color={textColor}>
            {status === FightStatus.DONE ? 'Historique des Combats' : 'Combats à venir'}
          </Text>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <VStack spacing={6}>
            {filteredFights.map((fight) => (
              <FightCard fightData={fight} status={status} key={fight.id} />
            ))}
          </VStack>
          {loading && <Loading />}
          {hasMore && !loading && (
            <Center>
              <Button
                mt={4}
                colorScheme={buttonColorScheme}
                onClick={() => setPage((prevPage) => prevPage + 1)}
              >
                Afficher plus
              </Button>
            </Center>
          )}
        </Box>
      );
    };
    
    export default FightHistory;
