import React, { useState } from 'react';
import {
    Box,
    Button,
    Input,
    VStack,
    HStack,
    useToast,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import { useColors } from '../constants/constants.js';

const AdminPanel = () => {
    const [numSimultaneousMatches, setNumSimultaneousMatches] = useState(1);
    const [isRunning, setIsRunning] = useState(false);
    const toast = useToast();
    const colors = useColors();

    const handleStart = () => {
        // Logic to start matches
        setIsRunning(true);
        toast({
            title: "Matches started.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    const handleStop = () => {
        // Logic to stop matches
        setIsRunning(false);
        toast({
            title: "Matches stopped.",
            status: "warning",
            duration: 5000,
            isClosable: true,
        });
    };

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="50%" boxShadow="lg">
            <VStack spacing={4}>
                <Box fontSize="xl" fontWeight="bold" mb={4}>
                    Configuration des Matchs
                </Box>
                <FormControl>
                    <HStack justify="space-between">
                        <FormLabel w="50%" textAlign="left" fontWeight="bold">Nombre de matchs simultanés</FormLabel>
                        <Input
                            w="50%"
                            type="number"
                            value={numSimultaneousMatches}
                            onChange={(e) => setNumSimultaneousMatches(e.target.value)}
                            borderColor="cyan.600"
                            focusBorderColor="cyan.400"
                        />
                    </HStack>
                </FormControl>
                <HStack spacing={4} mt={15}>
                    <Button colorScheme="green" onClick={handleStart} isDisabled={isRunning}>
                        Démarrer les matchs
                    </Button>
                    <Button colorScheme="red" onClick={handleStop} isDisabled={!isRunning}>
                        Arrêter les matchs
                    </Button>
                </HStack>
            </VStack>
        </Box>
    );
};

export default AdminPanel;
