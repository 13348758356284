import React from 'react';
import { useParams } from 'react-router-dom';
import { Game } from '../config';

const FightDetails = () => {
  const { fightId } = useParams();
  const url = `${Game.frontURL}?fightId=${fightId}`;

  return (
    <iframe src={url} style={{ width: '100%', height: '100vh', border: 'none' }} title="Fight Details"></iframe>
  );
};

export default FightDetails;
