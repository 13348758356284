import React from 'react';
import { Input, Box} from '@chakra-ui/react';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    return (
        <Box>
            <Input
                placeholder="Rechercher par groupe"
                value={searchTerm}
                onChange={handleChange}
                mb={4}
            />
        </Box>
    );
};

export default SearchBar;
