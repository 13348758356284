import React, { useState } from 'react';
import FightCard from './FightCard';
import { Box, HStack, VStack, Button, Flex } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import ErrorPopup from './ErrorPopup';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FightStatus, useColors } from '../constants/constants.js';
import { getFightItemJson } from '../api/fights/fights.ts';
import { useColorModeValue } from '@chakra-ui/react';

const FightList = ({ initialFights, myAgents, status }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const fightsPerPage = 4;
  const colors = useColors();

  const downLoadJson = async (fightId) => {
    try {
      const response = await getFightItemJson(fightId);
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
      saveAs(blob, `fight_${fightId}.json`);
    } catch (error) {
      handleShowError();
    }
  };

  const downloadAllJson = async () => {
    const zip = new JSZip();
    const fightPromises = initialFights.map(async (fight) => {
      try {
        const response = await getFightItemJson(fight.id);
        zip.file(`fight_${fight.id}.json`, JSON.stringify(response.data));
      } catch (error) {}
    });

    await Promise.all(fightPromises);

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, 'fights.zip');
      })
      .catch(handleShowError);
  };

  const handleShowError = () => {
    setShowError(true);
    setErrorMessage('Erreur lors du téléchargement du fichier JSON');
  };

  const handleCloseError = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const offset = currentPage * fightsPerPage;
  const currentFights = initialFights.slice(offset, offset + fightsPerPage);

  return (
    <>
      <ErrorPopup message={errorMessage} onClose={handleCloseError} isOpen={showError} />
      <Box padding={4} bgColor={colors.panelBg} maxW="5xl" margin="auto" borderRadius="lg" boxShadow="xl">
        <VStack spacing={4} width="100%" mb={4}>
          {currentFights.map(fight => (
            <HStack key={fight.id} width="100%" alignItems="center">
              <Box flex="1">
                <FightCard fightData={fight} myAgents={myAgents} status={status} />
              </Box>
              {status === FightStatus.DONE && (
                <Box>
                  <DownloadIcon onClick={() => downLoadJson(fight.id)} cursor="pointer" w={5} h={5} ml={2} color="teal.500" />
                </Box>
              )}
            </HStack>
          ))}
        </VStack>
        {status === FightStatus.DONE && (
          <HStack justifyContent="space-between" alignItems="center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(initialFights.length / fightsPerPage)}
              onPageChange={handlePageClick}
            />
            <Button leftIcon={<DownloadIcon />} colorScheme="teal" onClick={downloadAllJson}>
              Tout Télécharger
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
};

export default FightList;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const bgColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.300', 'gray.800');
  const activeBgColor = useColorModeValue('teal.500', 'teal.300');
  const textColor = useColorModeValue('black', 'white');

  const handleClick = (page) => {
    if (page >= 0 && page < totalPages) {
      onPageChange(page);
    }
  };

  const renderPageButtons = () => {
    const pages = [];

    if (totalPages <= 7) {
      // Show all pages if there are 7 or fewer
      for (let i = 0; i < totalPages; i++) {
        pages.push(
          <Button
            key={i}
            onClick={() => handleClick(i)}
            bg={currentPage === i ? activeBgColor : bgColor}
            color={currentPage === i ? 'white' : textColor}
            _hover={{ bg: currentPage === i ? activeBgColor : hoverBgColor }}
            mx={1}
          >
            {i + 1}
          </Button>
        );
      }
    } else {
      // Show first 4 pages, last 3 pages, and ellipsis if there are more than 7 pages
      for (let i = 0; i < 4; i++) {
        pages.push(
          <Button
            key={i}
            onClick={() => handleClick(i)}
            bg={currentPage === i ? activeBgColor : bgColor}
            color={currentPage === i ? 'white' : textColor}
            _hover={{ bg: hoverBgColor }}
            mx={1}
          >
            {i + 1}
          </Button>
        );
      }

      if (currentPage > 4 && currentPage < totalPages - 3) {
        pages.push(
          <Button key="ellipsis" disabled bg={bgColor} color={textColor} mx={1}>
            ...
          </Button>
        );
        pages.push(
          <Button
            key={currentPage}
            onClick={() => handleClick(currentPage)}
            bg={activeBgColor}
            color="white"
            _hover={{ bg: hoverBgColor }}
            mx={1}
          >
            {currentPage + 1}
          </Button>
        );
        pages.push(
          <Button key="ellipsis2" disabled bg={bgColor} color={textColor} mx={1}>
            ...
          </Button>
        );
      } else {
        pages.push(
          <Button key="ellipsis" disabled bg={bgColor} color={textColor} mx={1}>
            ...
          </Button>
        );
      }

      for (let i = totalPages - 3; i < totalPages; i++) {
        pages.push(
          <Button
            key={i}
            onClick={() => handleClick(i)}
            bg={currentPage === i ? activeBgColor : bgColor}
            color={currentPage === i ? 'white' : textColor}
            _hover={{ bg: hoverBgColor }}
            mx={1}
          >
            {i + 1}
          </Button>
        );
      }
    }

    return pages;
  };

  return (
    <Flex justifyContent="center" p={4}>
      <Button
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 0}
        bg={bgColor}
        color={textColor}
        _hover={{ bg: hoverBgColor }}
        mx={1}
      >
        Précédent
      </Button>
      {renderPageButtons()}
      <Button
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
        bg={bgColor}
        color={textColor}
        _hover={{ bg: hoverBgColor }}
        mx={1}
      >
        Suivant
      </Button>
    </Flex>
  );
};


