import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 

const ProtectedRoute = ({ element }) => {
    const { authState } = useAuth();
    if (authState.token) {
        return element;
    }

    return <Navigate to="/" />;
};

export default ProtectedRoute;
