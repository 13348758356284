import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { postStudentLogin } from '../api/students/students.ts';
import ErrorPopup from '../components/ErrorPopup.js';
import SuccessPopup from '../components/SuccessPopup.js';
import { getStudentAdminCheck } from '../api/students/students.ts';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    //const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true';
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(null);

    // Initializing authState from localStorage
    const initialAuthState = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return {
                    token: token,
                    student: { id: decodedToken.sub.id, username: decodedToken.sub.username, role: decodedToken.sub.role },
                };
            } catch (error) {
                localStorage.removeItem('token');
                return { token: null, student: null };
            }
        }
        return { token: null, student: null };
    };

    const [authState, setAuthState] = useState(initialAuthState);

    useEffect(() => {
        if (authState.token) { 
            try {
                const decoded = jwtDecode(authState.token);
                setAuthState((prevState) => ({
                    ...prevState,
                    student: { id: decoded.sub.id, username: decoded.sub.username, role : decoded.sub.role }
                }));
            }
            catch (error) {
                localStorage.removeItem('token');
                setErrorMessage('Token non valide');
                setShowError(true);
                setAuthState((prevState) => ({
                    ...prevState,
                    token: null,
                    student: null
                }));
            }
        }
    }, [authState.token]);

    useEffect(() => {
        const checkAdmin = async () => {
            if (authState.token) {
                try {
                    const options = { headers: { Authorization: `Bearer ${authState.token}` } };
                    const response = await getStudentAdminCheck(options);
                    setIsAdmin(response.data.is_admin);
                } catch (error) {
                    setIsAdmin(false);
                }
            }
            else {
                setIsAdmin(false);
            }

        };
        checkAdmin();
    }, [authState.token]);

    const login = async (student_id, password) => {
        try {
            const response = await postStudentLogin({ id: student_id, password: password });
            const token = response.data.access_token;
            const student = jwtDecode(token);
            localStorage.setItem('token', token);

            setAuthState({
                token,
                student: { id: student.sub.id, username: student.sub.username }
            });

            setErrorMessage('');
            setShowError(false);
            setSuccessMessage('Connexion réussie');
            setShowSuccess(true);

        } catch (error) {
            setErrorMessage('Identifiants invalides');
            setShowError(true);
            console.error('Login failed:', error);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('banner');
        setAuthState({
            token: null,
            student: null,
        });
        setIsAdmin(false);
    };

    return (
        <AuthContext.Provider value={{ authState, login, logout, isAdmin }}>
            <ErrorPopup message={errorMessage} onClose={() => setShowError(false)} isOpen={showError} />
            <SuccessPopup message={successMessage} onClose={() => setShowSuccess(false)} isOpen={showSuccess} />
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
