/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  Agent,
  UpdateAgent,
  UploadAgent
} from '.././model'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



export const postAgentCollection = (
    agent: Agent, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Agent>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/agents`,
      agent,options
    );
  }



export const getPostAgentCollectionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: Agent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: Agent}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentCollection>>, {data: Agent}> = (props) => {
          const {data} = props ?? {};

          return  postAgentCollection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentCollection>>>
    export type PostAgentCollectionMutationBody = Agent
    export type PostAgentCollectionMutationError = AxiosError<unknown>

    export const usePostAgentCollection = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: Agent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentCollection>>,
        TError,
        {data: Agent},
        TContext
      > => {

      const mutationOptions = getPostAgentCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAgentCollection = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Agent[]>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/agents`,options
    );
  }


export const getGetAgentCollectionQueryKey = () => {
    return [`https://demo.corail-lab.ca/api/agents`] as const;
    }

    
export const getGetAgentCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getAgentCollection>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentCollectionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentCollection>>> = ({ signal }) => getAgentCollection({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentCollection>>>
export type GetAgentCollectionQueryError = AxiosError<unknown>

export const useGetAgentCollection = <TData = Awaited<ReturnType<typeof getAgentCollection>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentCollectionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postAgentLoadCsv = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/agents/loadCSV`,undefined,options
    );
  }



export const getPostAgentLoadCsvMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentLoadCsv>>, void> = () => {
          

          return  postAgentLoadCsv(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentLoadCsv>>>
    
    export type PostAgentLoadCsvMutationError = AxiosError<unknown>

    export const usePostAgentLoadCsv = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostAgentLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postAgentUpload = (
    uploadAgent: UploadAgent, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://demo.corail-lab.ca/api/agents/upload`,
      uploadAgent,options
    );
  }



export const getPostAgentUploadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentUpload>>, {data: UploadAgent}> = (props) => {
          const {data} = props ?? {};

          return  postAgentUpload(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentUploadMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentUpload>>>
    export type PostAgentUploadMutationBody = UploadAgent
    export type PostAgentUploadMutationError = AxiosError<unknown>

    export const usePostAgentUpload = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentUpload>>,
        TError,
        {data: UploadAgent},
        TContext
      > => {

      const mutationOptions = getPostAgentUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAgentItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Agent>> => {
    
    return axios.get(
      `https://demo.corail-lab.ca/api/agents/${id}`,options
    );
  }


export const getGetAgentItemQueryKey = (id: number,) => {
    return [`https://demo.corail-lab.ca/api/agents/${id}`] as const;
    }

    
export const getGetAgentItemQueryOptions = <TData = Awaited<ReturnType<typeof getAgentItem>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentItem>>> = ({ signal }) => getAgentItem(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentItemQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentItem>>>
export type GetAgentItemQueryError = AxiosError<unknown>

export const useGetAgentItem = <TData = Awaited<ReturnType<typeof getAgentItem>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchAgentItem = (
    id: number,
    updateAgent: UpdateAgent, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Agent>> => {
    
    return axios.patch(
      `https://demo.corail-lab.ca/api/agents/${id}`,
      updateAgent,options
    );
  }



export const getPatchAgentItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchAgentItem>>, {id: number;data: UpdateAgent}> = (props) => {
          const {id,data} = props ?? {};

          return  patchAgentItem(id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchAgentItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchAgentItem>>>
    export type PatchAgentItemMutationBody = UpdateAgent
    export type PatchAgentItemMutationError = AxiosError<unknown>

    export const usePatchAgentItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchAgentItem>>,
        TError,
        {id: number;data: UpdateAgent},
        TContext
      > => {

      const mutationOptions = getPatchAgentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteAgentItem = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `https://demo.corail-lab.ca/api/agents/${id}`,options
    );
  }



export const getDeleteAgentItemMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAgentItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteAgentItem(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAgentItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgentItem>>>
    
    export type DeleteAgentItemMutationError = AxiosError<unknown>

    export const useDeleteAgentItem = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAgentItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteAgentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putAgentDelete = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `https://demo.corail-lab.ca/api/agents/${id}/deleteAgent`,undefined,options
    );
  }



export const getPutAgentDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putAgentDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  putAgentDelete(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutAgentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof putAgentDelete>>>
    
    export type PutAgentDeleteMutationError = AxiosError<unknown>

    export const usePutAgentDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putAgentDelete>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getPutAgentDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putAgentValidate = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Agent>> => {
    
    return axios.put(
      `https://demo.corail-lab.ca/api/agents/${id}/toggleStatus`,undefined,options
    );
  }



export const getPutAgentValidateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentValidate>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putAgentValidate>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putAgentValidate>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  putAgentValidate(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutAgentValidateMutationResult = NonNullable<Awaited<ReturnType<typeof putAgentValidate>>>
    
    export type PutAgentValidateMutationError = AxiosError<unknown>

    export const usePutAgentValidate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentValidate>>, TError,{id: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putAgentValidate>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getPutAgentValidateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    