
import {
    Box, Image,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, SimpleGrid
} from '@chakra-ui/react';

import { banners } from '../constants/constants.js';


const BannerSelectionModal = ({ isOpen, onClose, onSelect }) => {


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Selectionnez une bannière</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SimpleGrid columns={2} spacing={4}>
                        {banners.map((banner) => (
                            <Box
                                key={banner}
                                as="button"
                                onClick={() => onSelect(banner)}
                                border="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                overflow="hidden"
                            >
                                <Image src={`/banners/${banner}`} alt={banner} />
                            </Box>
                        ))}
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="pink" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default BannerSelectionModal;