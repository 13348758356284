import React from 'react';
import { Box, Tooltip, Icon } from '@chakra-ui/react';
import { TimeIcon, NotAllowedIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { AgentStatus } from '../constants/constants.js';

const getStatusIcon = (status, errorInfo) => {
  switch (status) {
    case AgentStatus.INACTIVE:
    case AgentStatus.ACTIVE:
      return <CheckCircleIcon />;
    case AgentStatus.INVALID:
      return <NotAllowedIcon boxSize={"20px"} />;
    case AgentStatus.PENDING:
      return <TimeIcon />;
    default:
      return <Icon color="red.500" as={NotAllowedIcon} />;
  }
};

const getTooltipLabel = (status, errorInfo) => {
  switch (status) {
    case AgentStatus.ACTIVE:
    case AgentStatus.INACTIVE:
      return 'Votre agent est valide';
    case AgentStatus.INVALID:
      return `Agent refusé : ${errorInfo}`;
    case AgentStatus.PENDING:
      return 'En attente de validation';
    default:
      return 'État inconnu';
  }
};

const StatusIconWithMessage = ({ status, errorInfo }) => {
  const tooltipLabel = getTooltipLabel(status, errorInfo);
  const iconElement = getStatusIcon(status, errorInfo);

  return (
    <Tooltip label={tooltipLabel} hasArrow placement="top" bg={'gray.800'} color={'white'}>
      <Box>
        {iconElement}
      </Box>
    </Tooltip>
  );
};

export default StatusIconWithMessage;
